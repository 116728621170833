import React from "react";

const CustomIconDashboard = (props) => (
  <span role="img" aria-label="rankings" className="anticon" {...props}>
   <svg style={{ width: 22, paddingBottom: 5 }} fill="currentcolor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">

  <circle cx="12" cy="12" r="10" stroke="#4A4A4A" stroke-width="2" fill="none"/>
  
  
  <path d="M4 12 A8 8 0 0 1 20 12" stroke="#4A4A4A" stroke-width="2" stroke-linecap="round"/>


  <line x1="6.5" y1="12" x2="7.5" y2="9" stroke="#4A4A4A" stroke-width="1.5"/>
  <line x1="9.5" y1="12" x2="10.2" y2="8" stroke="#4A4A4A" stroke-width="1.5"/>
  <line x1="12" y1="12" x2="12" y2="7" stroke="#4A4A4A" stroke-width="1.5"/>
  <line x1="14.5" y1="12" x2="13.8" y2="8" stroke="#4A4A4A" stroke-width="1.5"/>
  <line x1="17.5" y1="12" x2="16.5" y2="9" stroke="#4A4A4A" stroke-width="1.5"/>

 
  <path d="M12 12 L15 9" stroke="#4A4A4A" stroke-width="2" stroke-linecap="round"/>
  

  <circle cx="12" cy="12" r="1" fill="#4A4A4A"/>
</svg>
  </span>
);

export default CustomIconDashboard;
