import React, { useContext, useState } from "react";

import AddTag from "../../selects/AddTag";
import { Button, message, Steps, Modal } from "antd";
import { TagContext } from "../../../library/hooks/context/TagContext";
import MyAppsOverlay from "../../NavBar/Tabs/MyAppsOverlay";
import StartPage from "./StartPage";
import AddKeywords from "./AddKeywords";
export default function Wizard({ isModalOpen, showpage = 0, handleOk, handleCancel, workspacetext = "" }) {
  const [current, setCurrent] = useState(showpage);
  const next = () => {
    setCurrent(current + 1);
    setModalWidth(steps[current + 1].modalWidth);
  };
  const prev = () => {
    setCurrent(current - 1);
    setModalWidth(steps[current + 1].modalWidth);
  };

  const [modalWidth, setModalWidth] = useState("90vw");
  const tagContext = useContext(TagContext);
  const steps = [
    {
      title: "Welcome",
      content: <StartPage next={next} />,
      showNext: false,
      showPrevious: false,
      modalWidth: "75vw",
    },
    {
      title: "Create Workspace",
      content: (
        <>
          <div className="get-started__head">
            <h1 className="header header--page">Get started with your new workspace</h1>

            <p className="description" style={{ fontSize: 16 }}>
              Workspaces are where you manage your apps. Typically, you will create one workspace for each app and set of competitors.
            </p>

            <div style={{ textAlign: "center" }}>
              <h3>What name should your workspace have?</h3>
              <AddTag getStarted={true} next={next} workspacetext={workspacetext} />
            </div>
          </div>
        </>
      ),
      showNext: false,
      showPrevious: false,
      modalWidth: "75vw",
    },
    {
      title: "Add your App",
      content: (
        <>
          <div style={{ textAlign: "center" }}>
            <h3>
              Add at least one primary app.
              </h3>
              <p>Search by name or id. You can add both Android and iOS versions.</p>
              {tagContext.appList.length > 0 && (
                <>
                  {" "}
                  To continue press{" "}
                  <Button type="primary" onClick={() => next()}>
                    Next
                  </Button>
                </>
              )}
            
            <div style={{ paddingTop: 20 }}>
              <MyAppsOverlay key={Math.random()} onlyMyApps={true} onlyCompApps={false} wizardSearch={true} />
            </div>
          </div>
        </>
      ),
      showNext: false,
      showPrevious: false,
      modalWidth: "90vw",
    },
    {
      title: "Add your Competitors",
      content: (
        <>
          <div style={{ textAlign: "center" }}>
            <h3>
            Tracking competitors is vital for market insights, visibility, and staying ahead.
              </h3>
              <p>
              Search and add your competitors below{" "}</p>
              {tagContext.competitorAppList.length > 0 ? (
                <>
                  {" "}
                  and press{" "}
                  <Button type="primary" onClick={() => next()}>
                    Next
                  </Button>
                </>
              ) : (
                <>
                  {" "}
                  or{" "}
                  <Button type="primary" onClick={() => next()}>
                    Skip
                  </Button>
                </>
              )}
            
            <div style={{ paddingTop: 20 }}>
              <MyAppsOverlay key={Math.random()} onlyCompApps={true} onlyMyApps={false} />
            </div>
          </div>
        </>
      ),
      showNext: false,
      showPrevious: false,
      modalWidth: "90vw",
    },
    {
      title: "Keywords",
      content: (
        <>
          <h2>You are nearly set up. Let&lsquo;s get some keywords added.</h2>
          <AddKeywords />
        </>
      ),
      showNext: false,
      showPrevious: false,
      modalWidth: "40vw",
    },
  ];

  const items = steps.map((item) => ({
    key: item.title,
    title: "",
  }));

  const customDot = (dot, { status, index }) => <span></span>;

  return (
    <>
      <Modal width={modalWidth} open={isModalOpen} onOk={handleOk} onCancel={handleCancel} footer={null} destroyOnClose={true} closable={tagContext.tagList?.length !== 0} maskClosable={tagContext.tagList?.length !== 0} keyboard={tagContext.tagList?.length !== 0}>
        <Steps current={current} items={items} progressDot={customDot} size="small" className="custom-steps" />
        <div>{steps[current].content}</div>
        <div
          style={{
            marginTop: 50,
          }}
        >
          {current < steps.length - 1 && steps[current].showNext && (
            <Button type="primary" onClick={() => next()}>
              Next
            </Button>
          )}
          {current === steps.length - 1 && (
            <Button
              type="primary"
              onClick={() => {
                message.success("Let's Start!");
                handleOk();
              }}
            >
              Go to Keywords
            </Button>
          )}
          {current > 0 && steps[current].showPrevious && (
            <Button
              style={{
                margin: "0 8px",
              }}
              onClick={() => prev()}
            >
              Previous
            </Button>
          )}
        </div>
        <p className="description" style={{ fontSize: 16 }}>
          Need more help! View our tutorials{" "}
          <a href="/tutorials" target="_blank" rel="noopener noreferrer">
            here
          </a>
          .
        </p>
      </Modal>
    </>
  );
}
