import { Input, message, Space, Button } from "antd";
import React, { useContext, useState, useEffect } from "react";
import { addTag2 } from "../../library/helpers/tag-helper-functions";
import { TagContext } from "../../library/hooks/context/TagContext";

export default function AddTag({ next, workspacetext = "" }) {
	const tagContext = useContext(TagContext);
	const [newTag, setNewTag] = useState({});

	useEffect(() => {
		
		if (!tagContext.tagList?.length) {
			let value = "first workspace"
			let mtag={
				slug: value.replace(/\s/g, ""),
				Name: value,
			};
			addTag2({
				setTagList: tagContext.setTagList,
				newTag: mtag.Name,
				onSuccess: (response) => {
					let tag = response.response;
					tagContext.setSelectedTag(tag);
					tagContext.setContextValue("selectedCountry", {});
					tagContext.setContextValue("tagDataFetched", false);
	
					next();
				},
			});
		}


	}, []);

	const onTagTextInput = (value) => {
		setNewTag({
			slug: value.replace(/\s/g, ""),
			Name: value,
		});
	};

	useEffect(() => {
		if (workspacetext != "") {
			onTagTextInput(workspacetext);
		}
	}, [workspacetext]);

	const handleAddTag = () => {
		if (!newTag?.Name) {
			message.warning("Please enter a name for the new Workspace");
			return;
		}
		addTag2({
			setTagList: tagContext.setTagList,
			newTag: newTag.Name,
			onSuccess: (response) => {
				let tag = response.response;
				tagContext.setSelectedTag(tag);
				tagContext.setContextValue("selectedCountry", {});
				tagContext.setContextValue("tagDataFetched", false);

				next();
			},
		});
	};
	return (
		<Space
			align="center"
			style={{
				padding: "0 8px 4px",
			}}
			wrap={true}
		>
			<Input
				placeholder="New Workspace name"
				defaultValue={workspacetext}
				onChange={(e) => onTagTextInput(e.target.value)}
			/>

			<Button className="add-button" type="primary" onClick={handleAddTag}>
				Create and Next
			</Button>
		</Space>
	);
}
